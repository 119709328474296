import { ALL_APPOINTMENTS_GET } from "../../constant/ActionType";

export default function GetAllAppointments(
  state = {
    allAppointments: [],
  },
  action
) {
  switch (action.type) {
    case ALL_APPOINTMENTS_GET:
      return {
        ...state,
        allAppointments: action?.data?.data,
      };

    default:
  }

  return state;
}