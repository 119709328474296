import { useEffect, useState } from "react";
import MainCard from "components/MainCard";
import { Image, Table, Tooltip } from "antd";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { dispatch } from "store/index";
import { GetCustomListingAll, UpdatePreConstructionAll } from "store/action/index";
import { Link } from "react-router-dom";
import { IoMdAddCircle } from "react-icons/io";
import GetColumnSearchProps from "helper/searchHelper";
import { TbEdit } from "react-icons/tb";

const AllCustomProperty = ({ allCustomListingData }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  useEffect(() => {
    dispatch(GetCustomListingAll({ setLoading }));
  }, []);

  useEffect(() => {
    // Map through data and add key using id field
    const formattedData = allCustomListingData?.allCustomListingData?.map(blog => ({
      ...blog,
      key: blog.id, // Use id as the key
    })) ?? [];

    setDataSource(formattedData);
  }, [allCustomListingData]);

  const handleExpand = (expanded, record) => {
    setExpandedRowKeys((prevExpandedRowKeys) => {
      if (expanded) {
        // If the row is expanded, add its key to expandedRowKeys
        return [...prevExpandedRowKeys, record.key];
      } else {
        // If the row is collapsed, remove its key from expandedRowKeys
        return prevExpandedRowKeys.filter((key) => key !== record.key);
      }
    });
  };


  const handleUpdateStatus = (userId, newStatus) => {
    setLoading(true);
    let payload = {
      id: userId, status: newStatus
    }
    setTimeout(() => {
      dispatch(UpdatePreConstructionAll({
        payload, setLoading: setLoading
      }));

      setTimeout(() => {
        dispatch(GetCustomListingAll({ setLoading: setLoading }));
      }, 2400);
    }, 1000);
  };

  const handleEdit = (data) => {
    navigate("/custom_listings/create-custom_listings", { state: data });
  };

  const columns = [
    // {
    //   title: "Image",
    //   dataIndex: "photos",
    //   key: "photos",
    //   render: (text, record) => {
    //     const unescapedString = text?.replace(/\\"/g, '"');
    //     const photosArray = unescapedString && JSON.parse(unescapedString);
    //     return (
    //       <span>
    //         <Image
    //           width={50}
    //           alt={record.title}
    //           src={`https://www.luxlifemiamirealestate.com/api/storage/app/public/${photosArray && photosArray[0]}`}
    //         />
    //       </span>
    //     )
    //   },
    // },
    {
      title: "Listing ID",
      dataIndex: "listing_id",
      key: "listing_id",
      ...GetColumnSearchProps("listing_id"),
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      ...GetColumnSearchProps("city"),
    },
    {
      title: "Bed",
      dataIndex: "bedrooms",
      key: "bedrooms",
      ...GetColumnSearchProps("bedrooms"),

    },
    {
      title: "Bath",
      dataIndex: "bathrooms_total",
      key: "bathrooms_total",
      ...GetColumnSearchProps("bathrooms_total"),

    },
    {
      title: "Living Area",
      dataIndex: "living_area",
      key: "living_area",
      ...GetColumnSearchProps("living_area"),
      render: (text, record) => {
        return (
          <div>

            {text} sqft
          </div>
        )
      },
    },
    {
      title: "List Price",
      dataIndex: "original_list_price",
      key: "original_list_price",
      ...GetColumnSearchProps("original_list_price"),
      render: (text, record) => {
        return (
          <div>

            ${Number(text).toLocaleString()}
          </div>
        )
      },
    },
    // {
    //   title: "Status",
    //   key: "status",
    //   sorter: (a, b) => a.status - b.status,
    //   render: (text, record) => {
    //     return (
    //       <div style={{ textAlign: 'center' }}>

    //         {deactivateButton("deactivate-property-type", record, handleUpdateStatus)}
    //       </div>
    //     )
    //   },
    // },
    {
      title: "Edit",
      key: "actions",
      render: (text, record) => (
        <Tooltip title="Details">
          <TbEdit
            onClick={() => handleEdit(record)}
            style={{
              fontSize: "20px",
              color: "green",
              cursor: "pointer",
              margin: "5px",
            }}
          />
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          height: "75px",
          borderRadius: "12px",
          marginBottom: "20px",
          padding: "0 16px",
          backgroundColor: "#fff",
          border: '1px solid',
          borderColor: 'rgba(228,162,49,0.8)',
        }}
      >
        <Grid container alignItems="center" justifyContent="flex-start">
          <Grid item>
            <span
              style={{
                fontSize: "22px",
                fontWeight: "600",
              }}
            >
              All Custom Listing
            </span>
          </Grid>
        </Grid>
        <Grid container alignItems="center" justifyContent="flex-end">
          <Grid item>
            <Link to={"/custom_listings/create-custom_listings"} style={{ textDecoration: 'none', color: '#FF264C' }}>
              <span
                disableElevation
                className="px-2"
                size="large"
              >
                <span className="mx-2">
                  <IoMdAddCircle color="#FF264C" size={26} />
                </span>
                Add Custom Listing
              </span>
            </Link>
          </Grid>

        </Grid>
      </div>
      <MainCard>
        <Table
          style={{ overflowX: "auto" }}
          pagination={false}

          dataSource={dataSource} columns={columns} loading={loading}

          expandable={{
            expandedRowRender: (record) => (
              <div className="mb-4">
                <p><strong>ID:</strong> {record?.id}</p>
                <p><strong>City:</strong> {record?.city}</p>
                <p><strong>Property Subtype:</strong> {record?.property_subtype}</p>
                <p><strong>Listing ID:</strong> {record?.listing_id}</p>
                <p><strong>Street Number:</strong> {record?.street_number}</p>
                <p><strong>Street Name:</strong> {record?.street_name}</p>
                <p><strong>Street Direction Prefix:</strong> {record?.street_dir_prefix}</p>
                <p><strong>Postal Code:</strong> {record?.postal_code}</p>
                <p><strong>County or Parish:</strong> {record?.county_or_parish}</p>
                <p><strong>Latitude:</strong> {record?.latitude}</p>
                <p><strong>Longitude:</strong> {record?.longitude}</p>
                <p><strong>State:</strong> {record?.state}</p>
                <p><strong>List Price:</strong>  ${Number(record?.list_price).toLocaleString()}</p>
                <p><strong>Original List Price:</strong> {record?.original_list_price}</p>
                <p><strong>Bedrooms:</strong> {record?.bedrooms}</p>
                <p><strong>Total Bathrooms:</strong> {record?.bathrooms_total}</p>
                <p><strong>Living Area:</strong> {record?.living_area} sq ft</p>
                <p><strong>Status:</strong> {record?.standard_status}</p>
                <p><strong>Photos:</strong> {record?.photos.length > 0 ? "Available" : "No Photos Available"}</p>
                {record?.photos && record?.photos.length > 0 && <p>
                <strong>Photos:</strong>
                {record?.photos.map((photo, index) => (
                  <Image
                    key={index}
                    src={`https://www.luxlifemiamirealestate.com/api/storage/app/public/${photo}`}
                    alt={`Photo ${index + 1}`}
                    style={{ width: '100px', margin: '0 10px' }}
                  />
                ))}
              </p>}
                <p><strong>Created At:</strong> {record?.created_at}</p>
              </div>
            ),
            
            rowExpandable: () => true,
            onExpand: handleExpand,
            expandedRowKeys: expandedRowKeys,
          }}

        />
      </MainCard>
    </>
  );
};

const mapStateToProps = (state) => ({
  allCustomListingData: state?.GetAllCustomListing,
});

export default connect(mapStateToProps)(AllCustomProperty);
