// material-ui

import MainCard from 'components/MainCard';
import ThemeInput from 'components/inputs/ThemeInput';
import { useEffect, useState } from 'react';
import { Grid, InputLabel, Button } from '@mui/material';
import { Spin, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import {
  toast
} from 'react-toastify';
import { dispatch } from 'store/index';
import { CreatePreConstructionAll, EditPreConstruction, GetCustomListingAll } from 'store/action/index';
import { useLocation } from "react-router-dom";
import MultipleSelect from 'components/inputs/MultipleSelect';
import { connect } from 'react-redux';


// ==============================|| DASHBOARD - DEFAULT ||============================== //

const CreatePreconstruction = ({ allCustomListingData }) => {
  const location = useLocation()

  const [title, setTitle] = useState(location.state !== null ? location.state.title : "");
  const [bed, setBed] = useState(location.state !== null ? location.state.bed : "");
  const [bath, setBath] = useState(location.state !== null ? location.state.bath : "");
  const [address, setAddress] = useState(location.state !== null ? location.state.address : "");
  const [price_range, setPrice_Range] = useState(location.state !== null ? location.state.price_range : "");
  const [description, setDescription] = useState(location.state !== null ? location.state.description : "");
  const [locations, setLocations] = useState(location.state !== null ? location.state.location : "");
  const [website, setWebsite] = useState(location.state !== null ? location.state.website : "");
  const [residence, setResidence] = useState(location.state !== null ? location.state.residence : "");

  const [requirments, setrequirments] = useState(location.state !== null && location.state.features !== "" ? JSON.parse(location.state.features) : []);
  const [listing, setlisting] = useState(location.state !== null && location.state.resident !== null ? JSON.parse(location.state.resident) : []);

  const [image, setImage] = useState([]);
  const [docs, setDocs] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(GetCustomListingAll({ setLoading }));
  }, []);

  const handleImageUpload = async ({ file, onSuccess, onError }) => {
    try {
      setImage((prev) => [...prev, file]); // Add image to state
      onSuccess();
    } catch (error) {
      onError(error);
      toast.error('Upload failed');
    }
  };

  // Handle image removal
  const handleImageRemove = (file) => {
    setImage((prev) => prev.filter((img) => img.uid !== file.uid)); // Remove image from state
  };

  // Handle document upload
  const handleDocsUpload = async ({ file, onSuccess, onError }) => {
    try {
      setDocs((prev) => [...prev, file]); // Add document to state
      onSuccess();
    } catch (error) {
      onError(error);
      toast.error('Upload failed');
    }
  };

  // Handle document removal
  const handleDocsRemove = (file) => {
    setDocs((prev) => prev.filter((doc) => doc.uid !== file.uid)); // Remove document from state
  };

  const handleSubmit = () => {
    if (title !== "") {


      if (location.state !== null) {
        const payload = {
          title,
          bed,
          bath,
          address,
          price_range,
          description,
          location: locations,
          features: requirments.join(", "),
          website,
          residence,
          resident: listing,

          id: location.state?.id,
          ...(image && { photos: image, }),
          ...(docs && { docs: docs, }),
        }

        dispatch(EditPreConstruction({
          payload, setLoading: setLoading,
        }))

      } else {
        const payload = {
          title,
          bed,
          bath,
          address,
          price_range,
          description,
          location: locations,
          features: requirments.join(", "),
          website,
          residence,
          photos: image,
          docs: docs,
          resident:listing
        };
        if (!image) {
          toast.error("Image is required")
          return false
        }
        dispatch(CreatePreConstructionAll({ payload, setLoading: setLoading, }))
      }

    } else {
      toast.error("Required Fields are empty", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        bodyClassName: 'toastStyle',
      });
    }


  }


  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          height: "75px",
          borderRadius: "12px",
          marginBottom: "20px",
          padding: "0 16px",
          backgroundColor: "#fff",
          border: '1px solid',
          borderColor: 'rgba(228,162,49,0.8)',
        }}
      >
        <Grid container alignItems="center" justifyContent="flex-start">
          <Grid item>
            <span
              style={{
                fontSize: "22px",
                fontWeight: "600",
              }}
            >
              {location.state === null ? "Create" : "Edit"} Pre Construction
            </span>
          </Grid>
        </Grid>
      </div>
      <MainCard>
        <Spin spinning={loading}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className='row'>
                <div className='col-md-4 my-2'>
                  <ThemeInput id="title" value={title} onChange={(e) => setTitle(e.target.value)} type="text" name="Title*" placeholder="Eg. Title" />
                </div>
                <div className='col-md-4 my-2'>
                  <ThemeInput id="title" value={address} onChange={(e) => setAddress(e.target.value)} type="text" name="Address*" placeholder="Eg. Address" />
                </div>
                <div className='col-md-2 my-2'>
                  <ThemeInput id="title" value={bed} onChange={(e) => setBed(e.target.value)} type="text" name="Bed*" placeholder="Eg. Bed" />
                </div>
                <div className='col-md-2 my-2'>
                  <ThemeInput id="title" value={bath} onChange={(e) => setBath(e.target.value)} type="text" name="Bath*" placeholder="Eg. Bath" />
                </div>
                <div className='col-md-2 my-2'>
                  <ThemeInput id="title" value={website} onChange={(e) => setWebsite(e.target.value)} type="text" name="Website*" placeholder="Eg. Website" />
                </div>
                <div className='col-md-2 my-2'>
                  <ThemeInput id="title" value={residence} onChange={(e) => setResidence(e.target.value)} type="text" name="Residence*" placeholder="Eg. Residence" />
                </div>
                <div className='col-md-4 my-2'>
                  <ThemeInput id="title" value={price_range} onChange={(e) => setPrice_Range(e.target.value)} type="text" name="Price Range*" placeholder="Eg. Price Range" />
                </div>
                <div className='col-md-4 my-2'>
                  <ThemeInput id="title" value={description} onChange={(e) => setDescription(e.target.value)} type="text" name="Description*" placeholder="Eg. Description" />
                </div>
                <div className='col-md-4 my-2'>
                  <ThemeInput id="title" value={locations} onChange={(e) => setLocations(e.target.value)} type="text" name="Location URL*" placeholder="Eg. Location URL" />
                </div>

                <div className='col-md-4 my-2'>
                  <MultipleSelect
                    mode="tags"
                    name="Features*"
                    placeholder="Enter Features and hit Enter"
                    id="names"
                    onChange={(e) => setrequirments(e)}
                    defaultValue={requirments}
                  />
                </div>
                <div className='col-md-4 my-2'>
                  <MultipleSelect
                    mode="multiple"
                    name="Custom Listing*"
                    placeholder="Enter Custom Listing"
                    id="listings"
                    onChange={(e) => setlisting(e)}
                    defaultValue={listing}
                    options={allCustomListingData}
                  />
                </div>



                <div className='col-md-2 my-2'>
                  <InputLabel htmlFor="image-option">Images*</InputLabel>

                  <Upload
                    customRequest={handleImageUpload}
                    fileList={image}
                    onRemove={handleImageRemove}
                    listType="picture"
                    showUploadList={true}
                    multiple
                  >
                    <Button fullWidth
                      className="flex items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none my-1"
                    >
                      <UploadOutlined style={{ marginRight: 10 }} /> Upload Images
                    </Button>
                  </Upload>
                </div>
                <div className='col-md-2 my-2'>
                  <InputLabel htmlFor="image-option">Docs*</InputLabel>

                  <Upload
                    customRequest={handleDocsUpload}
                    fileList={docs}
                    onRemove={handleDocsRemove}
                    listType="text"
                    showUploadList={true}
                    multiple
                  >
                    <Button fullWidth
                      className="flex items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none my-1"
                    >
                      <UploadOutlined style={{ marginRight: 10 }} /> Upload Docs
                    </Button>
                  </Upload>
                </div>




              </div>


              <div className='my-3' style={{ textAlign: "end" }}>
                <Button
                  onClick={handleSubmit}
                  disableElevation
                  size="large"
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  {location.state === null ? "Add Pre Construction" : "Update Pre Construction"}
                </Button>

              </div>
            </Grid>
          </Grid>

        </Spin>
      </MainCard>

    </>
  );
};


const mapStateToProps = (state) => ({
  allCustomListingData: state?.GetAllCustomListing?.allCustomListingData,
});

export default connect(mapStateToProps)(CreatePreconstruction);