import { useEffect, useState } from "react";
import MainCard from "components/MainCard";
import { Table, Popconfirm } from "antd";
import { connect } from "react-redux";
import { Grid, Button } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { dispatch } from "store/index";
import { GetAppointmentsAll, UpdateStatusAppointments } from "store/action/index";
import GetColumnSearchProps from "helper/searchHelper";

const AllAppointments = ({ allAppointments }) => {
    const [loading, setLoading] = useState(true);
    const [dataSource, setDataSource] = useState([]);

    useEffect(() => {
        dispatch(GetAppointmentsAll({ setLoading }));
    }, []);

    useEffect(() => {
        const formattedData = allAppointments?.allAppointments?.map(blog => ({
            ...blog,
            key: blog.id,
        })) ?? [];

        setDataSource(formattedData);
    }, [allAppointments]);


    const handleUpdateStatus = (userId, newStatus) => {
        setLoading(true);
        let payload = {
            id: userId, status: newStatus
        }
        setTimeout(() => {
            dispatch(UpdateStatusAppointments({
                payload, setLoading: setLoading
            }));

            setTimeout(() => {
                dispatch(GetAppointmentsAll({ setLoading: setLoading }));
            }, 2400);
        }, 1000);
    };


    const columns = [
        {
            title: "Property ID",
            dataIndex: "property_id",
            key: "property_id",
            ...GetColumnSearchProps("property_id"),
        },
        {
            title: "Client Email",
            key: "client_email",
            render: (text, record) => {
                return (
                    <div>
                        {record?.user?.email}
                    </div>
                )
            }
        },
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
            ...GetColumnSearchProps("date"),
        },
        {
            title: "Time",
            dataIndex: "time",
            key: "time",
            ...GetColumnSearchProps("time"),
        },
        {
            title: "Type",
            dataIndex: "type",
            key: "type",
            ...GetColumnSearchProps("type"),
        },

    
        {
            title: "Status",
            key: "status",
            render: (text, record) => {
                return (
                    <div style={{ textAlign: 'center' }}>

<Popconfirm
      title={`Are you sure you want to ${
        record.status === 'confirmed' ? "Reject" : "Accept"
      } this appointment?`}
      onConfirm={() => handleUpdateStatus(record.id, record.status === 'confirmed' ? 'viewing' : 'confirmed')}
    >
      <Button
        variant="contained"
        color={record.status === 'confirmed' ? "success" : "error"}
      >
        {record.status === 'confirmed' ? <CheckIcon /> : <CloseIcon />}
      </Button>
    </Popconfirm>
                    </div>
                )
            },
        },
    ];

    return (
        <>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    height: "75px",
                    borderRadius: "12px",
                    marginBottom: "20px",
                    padding: "0 16px",
                    backgroundColor: "#fff",
                    border: '1px solid',
                    borderColor: 'rgba(228,162,49,0.8)',
                }}
            >
                <Grid container alignItems="center" justifyContent="flex-start">
                    <Grid item>
                        <span
                            style={{
                                fontSize: "22px",
                                fontWeight: "600",
                            }}
                        >
                            All Appointments
                        </span>
                    </Grid>
                </Grid>
            </div>
            <MainCard>
                <Table
                    style={{ overflowX: "auto" }}
                    pagination={false}

                    dataSource={dataSource} columns={columns} loading={loading}
                />
            </MainCard>
        </>
    );
};

const mapStateToProps = (state) => ({
    allAppointments: state?.GetAllAppointments,
});

export default connect(mapStateToProps)(AllAppointments);
