// material-ui
import logo from '../../assets/images/icons/luxlife_logo.svg'

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const LogoMain = () => {
  
  return (
  <img src={logo} alt="icon logo" width="140" />

  );
};

export default LogoMain;
