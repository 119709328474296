import React, { useEffect, useState } from "react";
import MainCard from "components/MainCard";
import { Table, Tooltip, Image } from "antd";
import { connect } from "react-redux";
import { Button, Grid } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { deactivateButton } from "components/actionButtons/actionsButtons";
import { dispatch } from "store/index";
import { GetPreConstructionAll, UpdatePreConstructionAll } from "store/action/index";
import { Link } from "react-router-dom";
import { IoMdAddCircle } from "react-icons/io";
import GetColumnSearchProps from "helper/searchHelper";
import { TbEdit } from "react-icons/tb";

const AllPreconstruction = ({ allPreconstructionData }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  useEffect(() => {
    dispatch(GetPreConstructionAll({ setLoading }));
  }, []);

  useEffect(() => {
    // Map through data and add key using id field
    const formattedData = allPreconstructionData?.allPreconstructionData?.map(blog => ({
      ...blog,
      key: blog.id, // Use id as the key
    })) ?? [];

    setDataSource(formattedData);
  }, [allPreconstructionData]);

  const handleExpand = (expanded, record) => {
    setExpandedRowKeys((prevExpandedRowKeys) => {
      if (expanded) {
        // If the row is expanded, add its key to expandedRowKeys
        return [...prevExpandedRowKeys, record.key];
      } else {
        // If the row is collapsed, remove its key from expandedRowKeys
        return prevExpandedRowKeys.filter((key) => key !== record.key);
      }
    });
  };


  const handleUpdateStatus = (userId, newStatus) => {
    setLoading(true);
    let payload = {
      id: userId, status: newStatus
    }
    setTimeout(() => {
      dispatch(UpdatePreConstructionAll({
        payload, setLoading: setLoading
      }));

      setTimeout(() => {
        dispatch(GetPreConstructionAll({ setLoading: setLoading }));
      }, 2400);
    }, 1000);
  };

  const handleEdit = (data) => {
    navigate("/preconstruction/create-preconstruction", { state: data });
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "photos",
      key: "photos",
      render: (text, record) => {
        const unescapedString = text?.replace(/\\"/g, '"');
        const photosArray = unescapedString && JSON.parse(unescapedString);
        return (
          <span>
            <Image
              width={50}
              alt={record.title}
              src={`https://www.luxlifemiamirealestate.com/api/storage/app/public/${photosArray && photosArray[0]}`}
            />
          </span>
        )
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      ...GetColumnSearchProps("title"),
    },
    
        {
          title: "Website",
          dataIndex: "website",
          key: "website",
          ...GetColumnSearchProps("website"),
          render: (text, record) => {
            return (
              <div >
    <a href={text} target="_blank">{text}</a>
                  </div>
            )
          },
        },
    {
      title: "Bed",
      dataIndex: "bed",
      key: "bed",
    },
    {
      title: "Bath",
      dataIndex: "bath",
      key: "bath",
    },
    {
      title: "Price Range",
      dataIndex: "price_range",
      key: "price_range",
    },
    {
      title: "Status",
      key: "status",
      sorter: (a, b) => a.status - b.status,
      render: (text, record) => {
        return (
          <div style={{ textAlign: 'center' }}>

            {deactivateButton("deactivate-property-type", record, handleUpdateStatus)}
          </div>
        )
      },
    },
    {
      title: "Edit",
      key: "actions",
      render: (text, record) => (
        <Tooltip title="Details">
          <TbEdit
            onClick={() => handleEdit(record)}
            style={{
              fontSize: "20px",
              color: "green",
              cursor: "pointer",
              margin: "5px",
            }}
          />
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          height: "75px",
          borderRadius: "12px",
          marginBottom: "20px",
          padding: "0 16px",
          backgroundColor: "#fff",
          border: '1px solid',
          borderColor: 'rgba(228,162,49,0.8)',
        }}
      >
        <Grid container alignItems="center" justifyContent="flex-start">
          <Grid item>
            <span
              style={{
                fontSize: "22px",
                fontWeight: "600",
              }}
            >
              All Pre Construction
            </span>
          </Grid>
        </Grid>
        <Grid container alignItems="center" justifyContent="flex-end">
          <Grid item>
            <Link to={"/preconstruction/create-preconstruction"} style={{ textDecoration: 'none', color: '#FF264C' }}>
              <span
                disableElevation
                className="px-2"
                size="large"
              >
                <span className="mx-2">
                  <IoMdAddCircle color="#FF264C" size={26} />
                </span>
                Add Pre Construction
              </span>
            </Link>
          </Grid>

        </Grid>
      </div>
      <MainCard>
        <Table
          style={{ overflowX: "auto" }}
          pagination={false}

          dataSource={dataSource} columns={columns} loading={loading}

          expandable={{
            expandedRowRender: (record) => (
              <div className="mb-4">
              <p><strong>Address:</strong> {record.address}</p>
              <p><strong>Description:</strong> {record.description}</p>
{JSON.parse(record?.features) && JSON.parse(record?.features).length !== 0 &&              <p><strong>Features:</strong></p>}
              <ul>
                {JSON.parse(record?.features) && JSON.parse(record?.features).length !== 0 && JSON.parse(record?.features).map((feature, index) => (
                  <li key={index}>{feature}</li>
                ))}
              </ul>
              <p>
                <strong>Photos:</strong>
                {JSON.parse(record?.photos.replace(/\\"/g, '"')).map((photo, index) => (
                  <Image
                    key={index}
                    src={`https://www.luxlifemiamirealestate.com/api/storage/app/public/${photo}`}
                    alt={`Photo ${index + 1}`}
                    style={{ width: '100px', margin: '0 10px' }}
                  />
                ))}
              </p>
              <p>
                <strong>Documents:</strong> <a href={`https://www.luxlifemiamirealestate.com/api/storage/app/public/${JSON.parse(record.docs)[0]}`} target="_blank" rel="noopener noreferrer">View Document</a>
              </p>
              <p>
                <strong>Location:</strong> <a href={record.location} target="_blank" rel="noopener noreferrer">View on Google Maps</a>
              </p>
              <p>
                <strong>Website:</strong> <a href={record.website} target="_blank" rel="noopener noreferrer">Visit Website</a>
              </p>
            </div>
                
            ),
            rowExpandable: () => true,
            onExpand: handleExpand,
            expandedRowKeys: expandedRowKeys,
          }}

        />
      </MainCard>
    </>
  );
};

const mapStateToProps = (state) => ({
  allPreconstructionData: state?.GetAllPreconstruction,
});

export default connect(mapStateToProps)(AllPreconstruction);
