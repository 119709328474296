import MainCard from 'components/MainCard';
import ThemeInput from 'components/inputs/ThemeInput';
import { useState } from 'react';
import { Grid, InputLabel, Button } from '@mui/material';
import { Spin, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { useLocation } from "react-router-dom";
import { CreateCustomListingAll, EditCustomListing } from 'store/action/index';
import { dispatch } from 'store/index';
import SingleSelect from 'components/inputs/SingleSelect';

const citiesArr = [
  "Coral Gables",
  "Fisher Island",
  "Bay Harbour",
  "Brickell",
  "Downtown",
  "Golden Beach",
  "Pinecrest",
  "South Miami",
  "Surfside",
  "Miami Beach",
  "Bal Harbour",
  "Bay Point",
  "Coconut Grove",
  "Edgewater",
  "Key Biscayne",
  "Ponce Davis",
  "Sunny Isles",
  "Upper East Side",
  "Manalapan",
  "Venetian Islands",
  "North Bay Road",
  "Indian Creek Village",
  "La Gorce & Allison Islands",
  "Pinetree Drive",
  "Star Island",
  "Palm Island",
  "Hibiscus Island",
  "Aventura"]

const standardStatusArr = ["Active", "Pending", "Sold"]

const propertySubTypeArr = [
  "Condominium",
  "Single Family Residence",
  "Industrial",
  "Quadruplex",
  "Retail",
  "Townhouse",
  "Residential",
  "Income/MultiFamily",
  "Office",
  "Mixed Use",
  "Duplex",
  "Agriculture",
  "Triplex",
  "Special Purpose",
  "Mobile Home",
  "Restaurant/Entertainment",
  "Commercial",
  "Hotel/Motel",
  "Dockominium",
  "Stock Cooperative",
  "Flex Space"
]

const CreateCustomProperty = () => {
  const location = useLocation();

  // State variables for each field
  const [city, setCity] = useState(location.state?.city || "");
  const [propertySubType, setPropertySubType] = useState(location.state?.property_subtype || "");
  const [streetNumber, setStreetNumber] = useState(location.state?.street_number || "");
  const [streetName, setStreetName] = useState(location.state?.street_name || "");
  const [streetDirPrefix, setStreetDirPrefix] = useState(location.state?.street_dir_prefix || "");
  const [postalCode, setPostalCode] = useState(location.state?.postal_code || "");
  const [countyOrParish, setCountyOrParish] = useState(location.state?.county_or_parish || "");
  const [latitude, setLatitude] = useState(location.state?.latitude || "");
  const [longitude, setLongitude] = useState(location.state?.longitude || "");
  const [state, setState] = useState(location.state?.state || "");
  const [listPrice, setListPrice] = useState(location.state?.list_price || "");
  const [standardStatus, setStandardStatus] = useState(location.state?.standard_status || "");
  const [bedrooms, setBedrooms] = useState(location.state?.bedrooms || "");
  const [bathroomsTotal, setBathroomsTotal] = useState(location.state?.bathrooms_total || "");
  const [livingArea, setLivingArea] = useState(location.state?.living_area || "");
  const [image, setImage] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (!listPrice || !bedrooms || !bathroomsTotal) {
      toast.error("Required fields are missing");
      return;
    }

    if (location.state !== null) {
      const payload = {
        city: city,
        property_subtype: propertySubType,
        street_number: streetNumber,
        street_name: streetName,
        street_dir_prefix: streetDirPrefix,
        postal_code: postalCode,
        county_or_parish: countyOrParish,
        latitude: latitude,
        longitude: longitude,
        state: state,
        list_price: listPrice,
        original_list_price: listPrice,
        bedrooms: bedrooms,
        bathrooms_total: bathroomsTotal,
        living_area: livingArea,
        standard_status: standardStatus,
        id: location.state?.id,
        ...(image && { photos: image, }),
      };
      dispatch(EditCustomListing({
        payload, setLoading: setLoading,
      }))
    } else {
      const payload = {
        city: city,
        property_subtype: propertySubType,
        street_number: streetNumber,
        street_name: streetName,
        street_dir_prefix: streetDirPrefix,
        postal_code: postalCode,
        county_or_parish: countyOrParish,
        latitude: latitude,
        longitude: longitude,
        state: state,
        list_price: listPrice,
        original_list_price: listPrice,
        bedrooms: bedrooms,
        bathrooms_total: bathroomsTotal,
        living_area: livingArea,
        standard_status: standardStatus,
        photos: image
      };
      dispatch(CreateCustomListingAll({
        payload, setLoading: setLoading,
      }))
    }

  };


  const handleImageUpload = async ({ file, onSuccess, onError }) => {
    try {
      setImage((prev) => [...prev, file]);
      onSuccess();
    } catch (error) {
      onError(error);
      toast.error('Upload failed');
    }
  };

  // Handle image removal
  const handleImageRemove = (file) => {
    setImage((prev) => prev.filter((img) => img.uid !== file.uid));
  };


  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          height: "75px",
          borderRadius: "12px",
          marginBottom: "20px",
          padding: "0 16px",
          backgroundColor: "#fff",
          border: '1px solid',
          borderColor: 'rgba(228,162,49,0.8)',
        }}
      >
        <Grid container alignItems="center" justifyContent="flex-start">
          <Grid item>
            <span
              style={{
                fontSize: "22px",
                fontWeight: "600",
              }}
            >
              {location.state === null ? "Create" : "Edit"} Custom Listing
            </span>
          </Grid>
        </Grid>
      </div>
      <MainCard>
        <Spin spinning={loading}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className='row'>
                <div className='col-md-4 my-2'>
                  <SingleSelect
                    name="City*"
                    placeholder="Select City"
                    id="city"
                    onChange={(e) => setCity(e)}
                    defaultValue={city}
                    options={citiesArr}
                  />
                  {/* <ThemeInput name="City" value={city} onChange={(e) => setCity(e.target.value)} placeholder="City" /> */}
                </div>
                <div className='col-md-2 my-2'>
                  <ThemeInput name="Street Number" value={streetNumber} onChange={(e) => setStreetNumber(e.target.value)} placeholder="Street Number" />
                </div>
                <div className='col-md-2 my-2'>
                  <ThemeInput name="Postal Code" value={postalCode} onChange={(e) => setPostalCode(e.target.value)} placeholder="Postal Code" />
                </div>
                <div className='col-md-4 my-2'>
                  <ThemeInput name="Street Name" value={streetName} onChange={(e) => setStreetName(e.target.value)} placeholder="Street Name" />
                </div>
                <div className='col-md-4 my-2'>
                  <ThemeInput name="Street Dir Prefix" value={streetDirPrefix} onChange={(e) => setStreetDirPrefix(e.target.value)} placeholder="Street Dir Prefix" />
                </div>
                <div className='col-md-4 my-2'>
                  <ThemeInput name="County or Parish" value={countyOrParish} onChange={(e) => setCountyOrParish(e.target.value)} placeholder="County or Parish" />
                </div>

                {/* Location Coordinates */}
                <div className='col-md-2 my-2'>
                  <ThemeInput name="Latitude" value={latitude} onChange={(e) => setLatitude(e.target.value)} placeholder="Latitude" />
                </div>
                <div className='col-md-2 my-2'>
                  <ThemeInput name="Longitude" value={longitude} onChange={(e) => setLongitude(e.target.value)} placeholder="Longitude" />
                </div>
                <div className='col-md-4 my-2'>
                  <ThemeInput name="State" value={state} onChange={(e) => setState(e.target.value)} placeholder="State" />
                </div>

                {/* Property Details */}
                <div className='col-md-4 my-2'>
                <SingleSelect
                    name="Property SubType*"
                    placeholder="Select Property SubType"
                    id="Property-SubType"
                    onChange={(e) => setPropertySubType(e)}
                    defaultValue={propertySubType}
                    options={propertySubTypeArr}
                  />
                  {/* <ThemeInput name="Property SubType" value={propertySubType} onChange={(e) => setPropertySubType(e.target.value)} placeholder="Property SubType" /> */}
                </div>
                <div className='col-md-4 my-2'>
                  <ThemeInput name="List Price" value={listPrice} required onChange={(e) => setListPrice(e.target.value)} placeholder="List Price" />
                </div>
                <div className='col-md-4 my-2'>
                <SingleSelect
                    name="Standard Status*"
                    placeholder="Select Standard Status"
                    id="Standard-Status"
                    onChange={(e) => setStandardStatus(e)}
                    defaultValue={standardStatus}
                    options={standardStatusArr}
                  />
                  {/* <ThemeInput name="Standard Status" value={standardStatus} onChange={(e) => setStandardStatus(e.target.value)} placeholder="Standard Status" /> */}
                </div>
                <div className='col-md-2 my-2'>
                  <ThemeInput name="Bedrooms" value={bedrooms} required onChange={(e) => setBedrooms(e.target.value)} placeholder="Bedrooms" />
                </div>
                <div className='col-md-2 my-2'>
                  <ThemeInput name="Bathrooms" value={bathroomsTotal} required onChange={(e) => setBathroomsTotal(e.target.value)} placeholder="Bathrooms" />
                </div>
                <div className='col-md-4 my-2'>
                  <ThemeInput name="Living Area (sq ft)" value={livingArea} onChange={(e) => setLivingArea(e.target.value)} placeholder="Living Area" />
                </div>

                {/* Photos */}
                <div className='col-md-2 my-2'>
                  <InputLabel htmlFor="image-option">Images*</InputLabel>

                  <Upload
                    customRequest={handleImageUpload}
                    fileList={image}
                    onRemove={handleImageRemove}
                    listType="picture"
                    showUploadList={true}
                    multiple
                  >
                    <Button fullWidth
                      className="flex items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none my-1"
                    >
                      <UploadOutlined style={{ marginRight: 10 }} /> Upload Images
                    </Button>
                  </Upload>
                </div>

                <div className='my-3' style={{ textAlign: "end" }}>
                  <Button
                    onClick={handleSubmit}
                    disableElevation
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {location.state ? "Update Property" : "Add Property"}
                  </Button>

                </div>
              </div>
            </Grid>
          </Grid>
        </Spin>
      </MainCard>
    </>
  );
};

export default CreateCustomProperty;
