import { useEffect, useState } from "react";
import {
  GetContactAll,
  DeleteContactAll,
  UpdateStatusContactUs,
} from "store/action/index";
import { Grid } from "@mui/material";
import { dispatch } from "store/index";
import { connect } from "react-redux";
import { Table } from "antd";
import MainCard from "../../components/MainCard";
import { deactivateButton } from "components/actionButtons/actionsButtons";
import GetColumnSearchProps from "helper/searchHelper";
const ContactUs = ({ allcontacts }) => {

  const [loading, setLoading] = useState(true);



  const handleUpdateStatus = (id, status) => {
    const payload = { id, status: status === 0 ? "initiated" : "contacted" };

    dispatch(UpdateStatusContactUs({ payload, setLoading }));

    setTimeout(() => {
      dispatch(GetContactAll({ setLoading }));
    }, 2400);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...GetColumnSearchProps("name"),
      width: "7%",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ...GetColumnSearchProps("email"),
      width: "7%",
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      ...GetColumnSearchProps("message"),
      width: "10%",
    },
    {
      title: "Topic",
      dataIndex: "topic",
      key: "topic",
      ...GetColumnSearchProps("topic"),
      width: "10%",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      ...GetColumnSearchProps("phone"),
      width: "10%",
    },
    {
      title: "Status",
      key: "status",
      width: "10%",
      sorter: (a, b) => a.status - b.status,
      render: (text, record) => {
        return (
          <div style={{ textAlign: "center" }}>
            {deactivateButton(
              "deactivate-property-type",
              record,
              handleUpdateStatus
            )}
          </div>
        );
      },
    },
  
  ];

  useEffect(() => {
    dispatch(GetContactAll({ setLoading: setLoading }));
  }, []);

  useEffect(() => {
    console.log("fsfsfdsdsdss", allcontacts);
  }, [allcontacts]);

  return (
    <>
       <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          height: "75px",
          borderRadius: "12px",
          marginBottom: "20px",
          padding: "0 16px",
          backgroundColor: "#fff",
          border: '1px solid',
          borderColor: 'rgba(228,162,49,0.8)',
        }}
      >
        <Grid container alignItems="center" justifyContent="flex-start">
          <Grid item>
            <span
              style={{
                fontSize: "22px",
                fontWeight: "600",
              }}
            >
              Contact Us
            </span>
          </Grid>
        </Grid>
        <Grid container alignItems="center" justifyContent="flex-end">
        </Grid>
      </div>      <MainCard>
          <Table
            dataSource={allcontacts ?? []}
            columns={columns}
            pagination={false}

            loading={loading}
          />
      </MainCard>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    allcontacts: state?.AllContactUs?.allcontacts,
  };
};

export default connect(mapStateToProps)(ContactUs);
