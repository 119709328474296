import React, { useState } from 'react';
import { Grid, InputLabel, Stack } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import { Select } from 'antd';

const MultipleSelect = (props) => {
  let {
    mode,
    name,
    placeholder,
    id,
    allowClear,
    errorText,
    error,
    disabled,
    defaultValue,
    onChange,
    options,
  } = props;

  const transformedOptions = options && options.length > 0 
    ? options.map(option => ({
        value: option.id, // Use the id as the value
        label: `${option.street_number} ${option.street_name} ${option.street_dir_prefix} ${option.county_or_parish}`, // Combine fields for the label
    }))
    : [];  // Default to an empty array if options is empty or undefined

    
    const formattedDefaultValue = defaultValue?.map((id) => {
      // Log the current id and find operation for debugging
      console.log('Finding option for id:', id);
      const option = options.find(option => option.id === Number(id));
      console.log('Found option:', option);
  
      return option
        ? {
            value: option.id, // Use the id as the value
            label: `${option.street_number} ${option.street_name} ${option.street_dir_prefix} ${option.county_or_parish}`,
          }
        : null;
    }).filter(item => item !== null) || [];
  
    console.log('Formatted Default Value:', formattedDefaultValue);
    return (
    <Grid item xs={12}>
      <Stack spacing={1}>
        <InputLabel htmlFor={id}>{name}</InputLabel>

        <Select
          mode={mode || "tags"}
          id={id}
          allowClear={allowClear}
          placeholder={placeholder}
          value={mode !== 'tags' ? formattedDefaultValue : defaultValue}
          disabled={disabled}
          onChange={onChange}
          options={transformedOptions}
          tokenSeparators={[',']}
          style={{ flex: 1 }}
          
        />

        {error ? (
          <FormHelperText style={{ margin: '0px 3%', color: 'red', fontSize: 10 }}>
            {errorText}
          </FormHelperText>
        ) : (
          <div></div>
        )}
      </Stack>
    </Grid>
  );
};

MultipleSelect.defaultProps = {
  errorText: '',
  error: false,
  options: [],
  value: [],
};


export default MultipleSelect;
