import dashboard from "./dashboard";
import blogs from "./allblogs";
import pre_Construction from "./pre-construction";
import messages from "./messages";
import Analytics from "./analytics";
import User from "./user";

const menuItems = {
  items: [
    dashboard,
    pre_Construction,
    blogs,
    messages,
    Analytics,
    User
  ]
};

export default menuItems;
