import { Messages1 } from 'iconsax-react';
import { CalendarTick } from 'iconsax-react';
import { CallCalling } from 'iconsax-react';

// icons
const icons = {
    navigation: Messages1,
    CalendarTick: CalendarTick,
    CallCalling: CallCalling,
};


const messages = {
      id: "Messages",
      title: "Messages",
      icon: icons.navigation,
      type: "group",
      children: [
        {
              id: "all-messages",
              title: "Messages",
              type: "item",
              url: "/messages/all-messages",
              icon: icons.navigation,
              breadcrumbs: true,
            },
        {
              id: "all-appointments",
              title: "Appointments",
              type: "item",
              url: "/appointments/all-appointments",
              icon: icons.CalendarTick,
              breadcrumbs: true,
            },
        {
              id: "all-contact",
              title: "Contact Us",
              type: "item",
              url: "/contact_us/all-contact_us",
              icon: icons.CallCalling,
              breadcrumbs: true,
            },
      ],
    };

export default messages;
