import { ALL_CUSTOM_LISTING_GET } from "../../constant/ActionType";

export default function GetAllCustomListing(
  state = {
    allCustomListingData: [],
  },
  action
) {
  switch (action.type) {
    case ALL_CUSTOM_LISTING_GET:
      return {
        ...state,
        allCustomListingData: action?.data?.data,
      };

    default:
  }

  return state;
}